import Icon, { IconProps } from '../Icon';
const StatusDotQuarterIcon = (props: IconProps) => (
  <Icon viewBox="0 0 14 14" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M2.545 7a4.455 4.455 0 1 0 8.91 0 4.455 4.455 0 0 0-8.91 0ZM7 0a7 7 0 1 0 0 14A7 7 0 0 0 7 0Z"
      clipRule="evenodd"
    />
    <path d="M14 7a7.002 7.002 0 0 0-7-7v7h7Z" />
  </Icon>
);
export default StatusDotQuarterIcon;
