import {
  Stack,
  Typography,
  Box,
  Card,
  CardActionArea,
  CardContent,
} from '@mui/material';
import { Trans } from '@lingui/react/macro';
import ConversationIcon from '@watershed/icons/components/Conversation';
import { GQSupportCaseForSupportPanelFragment } from '@watershed/shared-universal/generated/graphql';
import BlankSlate from '@watershed/ui-core/components/BlankSlate';
import gql from 'graphql-tag';
import SheetPanel from '@watershed/ui-core/components/Sheet/SheetPanel';
import { PAGE_HEADER_Z_INDEX } from '@watershed/shared-frontend/components/PageHeader';
import { routeForSupportCase } from '@watershed/shared-universal/dashboardRoutes';
import { SupportButton, SupportMenuItemProps } from '../support/SupportButton';
import { createDialogHook } from '@watershed/ui-core/hooks/useDialog';
import BoxLink from '@watershed/ui-core/components/BoxLink';
import { useSupportCaseListForPanelQuery } from '@watershed/shared-frontend/generated/urql';
import flattenConnection from '@watershed/shared-universal/utils/flattenConnection';
import Skeleton from '@watershed/ui-core/components/Skeleton';
import { isFetchingOrDataBang } from '@watershed/shared-frontend/utils/isFetchingOrStale';
import { formatDate } from '@watershed/intl/formatters';
import useLocale from '@watershed/intl/frontend/useLocale';
import {
  getPriorityLabel,
  getStatusLabel,
} from '@watershed/shared-universal/entFound/supportUtils';
import SupportCasePriorityIcon from './SupportCasePriorityIcon';
import SupportCaseStatusIcon from './SupportCaseStatusIcon';

const SUPPORT_PANEL_WIDTH_PX = 350;

gql`
  fragment SupportCaseForSupportPanel on SupportCase {
  id
  subject
  description
  status
  priority
  updatedAt
  lastUpdatedBy
}

query SupportCaseListForPanel(
  $after: String,
  $before: String,
  $first: Int,
  $last: Int,
  $objectId: String,
) {
  supportCases(
    after: $after,
    before: $before,
    first: $first,
    last: $last,
    objectId: $objectId
  ) {
    edges {
      cursor
      node {
        ...SupportCaseForSupportPanel
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
      totalRowCount
    }
  }
}
`;

function SupportCaseCard({
  supportCase,
}: {
  supportCase: GQSupportCaseForSupportPanelFragment;
}) {
  const locale = useLocale();
  const { status, priority, subject, updatedAt, description, lastUpdatedBy } =
    supportCase;

  return (
    <BoxLink href={routeForSupportCase(supportCase.id)}>
      <Card>
        <CardActionArea>
          <CardContent>
            <Stack direction="column" spacing={1}>
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <Typography variant="h3" noWrap>
                  {subject}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  variant="body3"
                  sx={{ color: (theme) => theme.palette.slate }}
                >
                  {lastUpdatedBy}
                </Typography>
                <Typography
                  variant="body3"
                  sx={{ color: (theme) => theme.palette.slate }}
                >
                  {formatDate(updatedAt, { locale })}
                </Typography>
              </Stack>
              {description ? (
                <Typography
                  variant="body2"
                  sx={{
                    textWrap: 'pretty',
                    maxHeight: '120px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {description}
                </Typography>
              ) : null}
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <SupportCaseStatusIcon status={status} />
                  <Typography variant="body3">
                    {getStatusLabel(status)}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <Typography variant="body3">
                    {getPriorityLabel(priority)}
                  </Typography>
                  <SupportCasePriorityIcon priority={priority} />
                </Stack>
              </Stack>
            </Stack>
          </CardContent>
        </CardActionArea>
      </Card>
    </BoxLink>
  );
}

export type SupportPanelProps = SupportMenuItemProps & {
  onClose: () => void;
  objectId: string;
};

/**
 * The Support Panel
 *
 * It is important that our users experience a consistent support experience
 * across our product, which means that we need to avoid bespoke support
 * UI created by individual teams. This component is the canonical answer for
 * an object-specific panel that displays support cases related to that object.
 *
 * The first implementation case is for User Upload Tasks, to replace Data
 * Issues, but any team can use this panel to display support cases related to
 * their objects.
 */
export function SupportPanel({
  objectId,
  onClose,
  ...menuItemProps
}: SupportPanelProps) {
  const [result] = useSupportCaseListForPanelQuery({
    // TODO: How do we paginate this?
    variables: { objectId, after: null, before: null, first: null, last: null },
    requestPolicy: 'cache-and-network',
  });
  const { fetching, data } = isFetchingOrDataBang(result);
  const supportCases = flattenConnection(data?.supportCases);
  const totalSupportCaseCount = data?.supportCases.pageInfo.totalRowCount ?? 0;
  return (
    <SheetPanel
      onClose={onClose}
      open
      title={
        <Typography variant="h2">
          {totalSupportCaseCount > 0 ? (
            <Trans comment="Support panel subheading above a list of cases">
              Support cases ({totalSupportCaseCount})
            </Trans>
          ) : (
            <Trans comment="Support panel subheading">Support cases</Trans>
          )}
        </Typography>
      }
      sx={{ zIndex: PAGE_HEADER_Z_INDEX + 1 }}
      position="fixed"
    >
      <Box
        sx={(theme) => ({
          flexBasis: SUPPORT_PANEL_WIDTH_PX,
          flexShrink: 0,
          maxWidth: SUPPORT_PANEL_WIDTH_PX,
          [theme.breakpoints.down('md')]: {
            maxWidth: '230px',
          },
        })}
      >
        {fetching ? (
          <Stack gap={3}>
            <Skeleton height={24} width={80} />
            <SupportButton {...menuItemProps} objectId={objectId} />
          </Stack>
        ) : (
          <Stack gap={3}>
            <SupportButton {...menuItemProps} objectId={objectId} />
            {supportCases.length > 0 ? (
              <Stack spacing={2}>
                {supportCases.map((supportCase) => (
                  <SupportCaseCard
                    key={supportCase.id}
                    supportCase={supportCase}
                  />
                ))}
              </Stack>
            ) : (
              <BlankSlate
                icon={ConversationIcon}
                title={
                  <Trans>
                    There currently are no support cases associated with this
                    page.
                  </Trans>
                }
                size="small"
              />
            )}
          </Stack>
        )}
      </Box>
    </SheetPanel>
  );
}

export const useSupportPanel =
  createDialogHook<SupportPanelProps>(SupportPanel);
