import gql from 'graphql-tag';
import { Trans } from '@lingui/react/macro';
import Button, { ButtonProps } from '@watershed/ui-core/components/Button';
import ConversationIcon from '@watershed/icons/components/Conversation';
import {
  GQDiscussionAnchor,
  GQFlags,
  GQSupportCaseIssueType,
} from '@watershed/shared-universal/generated/graphql';
import { useSupportCaseNotificationCountQuery } from '@watershed/shared-frontend/generated/urql';
import {
  useDiscussionsDialog,
  useDiscussionsQuery,
} from '../discussions/DiscussionsDialog';
import { OpenDiscussionCount } from '../discussions/OpenDiscussionCount';
import { useRouter } from 'next/router';
import HelpIcon from '@watershed/icons/components/Help';
import { Stack, ChipProps } from '@mui/material';
import { useFeatureFlag } from '../../utils/FeatureFlag';
import DropdownMenu, {
  type DropdownItem,
} from '@watershed/ui-core/components/DropdownMenu';
import { ErrorBoundary } from 'react-error-boundary';
import { maybeNotifySentry } from '@watershed/shared-frontend/utils/errorUtils';
import { useSupportCaseDialog } from '../support/NewSupportCaseDialog';
import isNotNullish from '@watershed/shared-util/isNotNullish';
import { ReactNode } from 'react';
import LightBulbIcon from '@watershed/icons/components/LightBulb';
import ArrowNEIcon from '@watershed/icons/components/ArrowNE';
import { useSupportPanel } from './SupportPanel';
import must from '@watershed/shared-universal/utils/must';

gql`
fragment CommentDiscussionsForAnchorFields on DiscussionsPayload {
    id
    discussions {
      ...DiscussionFields
    }
  }
  query CommentDiscussionsForAnchor($input: DiscussionAnchor!) {
    commentDiscussionsForAnchor(input: $input) {
      ...CommentDiscussionsForAnchorFields
    }
  }

  query SupportCaseNotificationCount($objectId: ID, $issueType: SupportCaseIssueType) {
    supportCaseNotificationCount(objectId: $objectId, issueType: $issueType)
  }
`;

export type SupportMenuItemProps = {
  issueType: GQSupportCaseIssueType;
  objectId?: string | null;
  labelForLearningHub?: ReactNode;
  routeForLearningHub?: string;
  withIcons?: boolean;

  // DEPRECATED
  anchor: GQDiscussionAnchor;
};

type SupportButtonProps = SupportMenuItemProps & {
  launchPanel?: boolean;
  hideLabel?: boolean;
  buttonProps?: Pick<ButtonProps, 'sx' | 'color'>;
  chipProps?: Pick<ChipProps, 'sx'>;
  variant?: 'contained' | 'outlined' | 'text';
};

/**
 * The Support Button
 *
 * It is important that our users experience a consistent support experience
 * across our product, which means that we need to avoid bespoke support
 * buttons created by individual teams. This component is the canonical answer.
 *
 * By supplying an issue type, you can open a support dialog with the correct
 * feature pre-selected.
 *
 * If you are displaying the button in the context of a specific object like a
 * footprint or user upload task, you can supply the object ID so that you can
 * filter cases by object and link back to the appropriate url from the case.
 *
 * If your feature has a learning hub article, you can supply the route to that
 * article for easy user access and optionally a label for the menu item.
 *
 * Finally, if you have an existing dropdown menu with addition actions to take,
 * and you just want access to the Support Button menu items, we export a helper
 * below to achieve this.
 */
export function SupportButton({
  launchPanel,
  hideLabel,
  buttonProps,
  chipProps,
  variant,
  ...menuItemProps
}: SupportButtonProps) {
  const openPanel = useSupportPanel();
  const { menuItems, fetching, handleOpenDialog, openDiscussionCount } =
    useSupportMenuItems(menuItemProps);
  const handleClick = launchPanel
    ? () =>
        openPanel({
          ...menuItemProps,
          objectId: must(
            menuItemProps.objectId,
            'objectId must be specified if launchPanel is true'
          ),
        })
    : handleOpenDialog;

  return (
    <ErrorBoundary
      fallbackRender={() => null}
      onError={(error) => maybeNotifySentry(error)}
    >
      {!launchPanel && menuItems.length > 1 ? (
        <DropdownMenu
          triggerEndIcon={
            hideLabel ? (
              <Button isIcon>
                <HelpIcon />
              </Button>
            ) : undefined
          }
          trigger={
            hideLabel ? undefined : (
              <Stack direction="row" alignItems="center" gap={1}>
                <HelpIcon size={16} />

                <Trans context="button copy to trigger a menu with support options">
                  Help
                </Trans>
              </Stack>
            )
          }
          items={menuItems}
        />
      ) : (
        <Button
          onClick={handleClick}
          disabled={fetching}
          startIcon={<ConversationIcon />}
          variant={variant}
          {...buttonProps}
        >
          <OpenDiscussionCount
            count={openDiscussionCount}
            label={
              <Trans context="button copy to open a support dialog">
                Support
              </Trans>
            }
            chipProps={chipProps}
          />
        </Button>
      )}
    </ErrorBoundary>
  );
}

/**
 * In case these items are being embedded in another dropdown menu, you can use
 * this function to just get the dropdown items.
 */
export function useSupportMenuItems({
  withIcons,
  issueType,
  objectId,
  anchor,
  labelForLearningHub,
  routeForLearningHub,
}: SupportMenuItemProps): {
  fetching: boolean;
  handleOpenDialog: () => void;
  openDiscussionCount: number;
  menuItems: Array<DropdownItem>;
} {
  const newSupportUi = useFeatureFlag(GQFlags.EntFoundSupportUi);
  const router = useRouter();

  // Which dialog should we open?
  const openSupportCaseDialog = useSupportCaseDialog();
  const openDiscussionsDialog = useDiscussionsDialog();
  const handleOpenDialog = () =>
    newSupportUi
      ? openSupportCaseDialog({ issueType, objectId })
      : openDiscussionsDialog({ anchor });

  // Which queries should we run?
  const [supportCaseResult] = useSupportCaseNotificationCountQuery({
    variables: {
      issueType,
      objectId: null,
    },
    pause: !newSupportUi,
  });
  const useDiscussionResult = useDiscussionsQuery({
    anchor,
    pause: newSupportUi,
  });
  const fetching = newSupportUi
    ? supportCaseResult.fetching
    : useDiscussionResult.fetching;
  const openDiscussionCount = newSupportUi
    ? (supportCaseResult.data?.supportCaseNotificationCount ?? 0)
    : useDiscussionResult.openDiscussionCount;

  let learningHubLabel = labelForLearningHub ?? (
    <Trans context="Menu item for a link to our learning hub">
      Learning hub
    </Trans>
  );
  if (withIcons) {
    learningHubLabel = (
      <Stack direction="row" alignItems="center" gap={1}>
        {learningHubLabel}
        <ArrowNEIcon />
      </Stack>
    );
  }

  return {
    fetching,
    handleOpenDialog,
    openDiscussionCount,
    menuItems: [
      routeForLearningHub
        ? {
            id: 'LearningHub',
            label: learningHubLabel,
            onSelect: () => {
              void router.push(routeForLearningHub);
            },
            Icon: withIcons ? LightBulbIcon : undefined,
          }
        : null,
      {
        id: 'SupportDialog',
        label: (
          <OpenDiscussionCount
            count={openDiscussionCount}
            label={
              <Trans context="button copy to open a support dialog">
                Support
              </Trans>
            }
          />
        ),
        onSelect: handleOpenDialog,
        Icon: withIcons ? ConversationIcon : undefined,
      },
    ].filter(isNotNullish),
  };
}
