import CircleCheckmarkFillIcon from '@watershed/icons/components/CircleCheckmarkFill';
import StatusDotQuarterIcon from '@watershed/icons/components/StatusDotQuarter';
import { GQSupportCaseStatus } from '@watershed/shared-universal/generated/graphql';

export default function SupportCaseStatusIcon({
  status,
  size,
}: { status: GQSupportCaseStatus; size?: number }) {
  if (status === GQSupportCaseStatus.PendingWatershedReply) {
    return (
      <StatusDotQuarterIcon
        size={size ?? 13}
        color={(theme) => theme.palette.cobalt}
      />
    );
  }
  if (status === GQSupportCaseStatus.PendingCustomerReply) {
    return (
      <StatusDotQuarterIcon
        size={size ?? 13}
        color={(theme) => theme.palette.marigold}
      />
    );
  }
  if (status === GQSupportCaseStatus.OnHoldPendingWatershedAction) {
    return (
      <StatusDotQuarterIcon
        size={size ?? 13}
        color={(theme) => theme.palette.cobalt}
      />
    );
  }

  return (
    <CircleCheckmarkFillIcon
      size={size ?? 14}
      color={(theme) => theme.palette.grass}
    />
  );
}
