import Icon, { IconProps } from '../Icon';
const ArrowNEIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M17.925 6.6a.906.906 0 0 0-.525-.525C17.25 6 17.1 6 17.025 6H6.975A.993.993 0 0 0 6 6.975c0 .525.45.975.975.975h7.575L6.3 16.275c-.375.375-.375 1.05 0 1.425s1.05.375 1.425 0l8.325-8.325v7.575c0 .525.45.975.975.975.525 0 .975-.45.975-.975V6.975c0-.075 0-.225-.075-.375Z"
      clipRule="evenodd"
    />
  </Icon>
);
export default ArrowNEIcon;
